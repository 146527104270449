// const url = "http://localhost:8000/api/";
const url = "https://api.ecominsider.net/api/";
// const url = "https://uatapi.ecominsider.net/api/";
const APIURLConstants = {
	POST_LOGIN: `${url}login`,
	POST_SIGN_UP: `${url}register`,
	// GOOGLE: `${url}login/google`,
	GOOGLE: `${url}social/login/`,
	GOOGLE_CALLBACK: `${url}login/callback`,
	get_products: `${url}products`,
	ADD_TO_CART: `${url}addToCart`,
	GET_CART: `${url}cart`,
	REMOVE_CART_ITEM: `${url}cartProductRemove`,
	PAYMENT: `${url}payment`,
	GET_MY_PRODUCTS: `${url}myProducts`,
	GET_PRODUCT_CART: `${url}productCartDetails`,
	POST_CONTACT: `${url}contactUs`,
	add_products_cart: `${url}addProductsToCart`,
	add_products_myaccount: `${url}addProductsToMyAccount`,
	USER_DETAILS: `${url}userDetail`,
	SUBSCRIPTION_DETAILS: `${url}subScriptionDetails`,
	PAYMENT_DETAILS: `${url}paymentDetails`,
	UPDATE_USER: `${url}updateUser`,
	ALL_USER: `${url}allUsers`,
	DASHBOARD_DETAILS: `${url}dashboardDetails`,
	ALL_FEEDS: `${url}allFeeds`,
	POST_FEED: `${url}insUpdFeed`,
	SAVE_CARD: `${url}saveCard`,
	ALL_CARD: `${url}getAllCard`,
	DELETE_CARD: `${url}deleteCard`,
	POST_FAQ: `${url}addFaq`,
	GET_FAQ: `${url}getFaq`,
	IMAGE_UPLOAD: `${url}imageUpload`,
	UPDATE_USER_PWD: `${url}updateUserPwd`,
	REMOVE_MYPRODUCT_ITEM: `${url}removeMyProducts`,
	DELETE_FAQ: `${url}deleteFaq`,
	DELETE_FEED: `${url}deleteFeed`,
	SEND_OTP: `${url}sendOTP`,
	PASSWORD_RESET: `${url}pwdReset`,
};

export default APIURLConstants;
